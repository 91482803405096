<template>
  <div class="composant-details-transfert">
    <!-- <p class="numero-transfert">{{ numeroTransfert }}</p>
    <p class="quantite-transfert">
      {{ quantiteTransfert }}
    </p>
    <p class="details-lieux-transfert">
      {{ detailsLieuxTransfert }}
    </p> -->
    <LabelEditable
      @change="modifierTransfert"
      :locked="locked || !user.adminAccessGranted"
      class="numero-transfert" 
      preLabel="Numero " 
      format="select" 
      selectListValue="index"
      :selectList='listeNumeros.map((numero)=>numero.numero_numero)' 
      v-model="numeroTransfert" 
    />
    <LabelEditable
      @change="modifierTransfert"
      :locked="locked || !user.adminAccessGranted"
      class="quantite-transfert"  
      postLabel=" ex." 
      v-model="quantiteTransfert"   
    />
    <p class="details-lieux-transfert labels">De&nbsp;</p>
    <LabelEditable
      @change="modifierTransfert"
      :locked="locked || !user.adminAccessGranted"
      class="details-lieux-transfert" 
      format="select" 
      selectListValue="index"
      :selectList='listeLieux.map((lieu)=>lieu.nom_lieu)' 
      v-model="provenanceTransfert" 
    />
    <!-- <select 
      @change="modifierTransfert"
      class="details-lieux-transfert" 
      v-model="provenanceTransfert" 
    >
      <option v-for="(lieu, index) of listeLieux" :key="lieu.id_lieu" :value="index">{{ lieu.nom_lieu }}</option>
    </select> -->

    <p class="details-lieux-transfert labels">&nbsp;vers&nbsp;</p>
    <LabelEditable
      @change="modifierTransfert"
      :locked="locked || !user.adminAccessGranted"
      class="details-lieux-transfert" 
      format="select" 
      selectListValue="index"
      :selectList='listeLieux.map((lieu)=>lieu.nom_lieu)' 
      v-model="destinationTransfert" 
    />
    <!-- <p class="details-lieux-transfert">{{ detailsLieuxTransfert }}</p> -->
    <p
      v-if="user.adminAccessGranted"
      class="supprimer-transfert element-clickable" 
      @click="supprimerTransfert"
    >❌</p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: "ComposantDetailsTransfert",
  data: () => {
    return { 
      locked: false,
      provenanceTransfert: "",
      destinationTransfert: "",
      numeroTransfert: "",
      quantiteTransfert: -1,
      privateTransfert: {}
    }
  },
  props: {
    transfert: { default: { 
      numero_numero: "inconnu", 
      quantite_transfert: -1,
      provenance: { nom_lieu: "ici" },
      destination: { nom_lieu: "là bas" }
    }}
  },
  methods: {
    ...mapActions(['phpPost']),
    modifierTransfert() {
      // this.privateTransfert = this.transfert
      let privateTransfert = {
        id_transfert: this.transfert.id_transfert,
        id_numero: this.listeNumeros[this.numeroTransfert].id_numero,
        provenance_transfert: this.listeLieux[this.provenanceTransfert].id_lieu,
        destination_transfert: this.listeLieux[this.destinationTransfert].id_lieu,
        quantite_transfert: this.quantiteTransfert,
        date_transfert: this.transfert.date_transfert
      }
      // this.privateTransfert.idTransfert = this.transfert.id_transfert
      // this.privateTransfert.numeroTransfert = this.listeNumeros[this.numeroTransfert].id_numero
      // this.privateTransfert.idProvenanceTransfert = this.listeLieux[this.provenanceTransfert].id_lieu
      // this.privateTransfert.idDestinationTransfert = this.listeLieux[this.destinationTransfert].id_lieu
      // this.privateTransfert.quantiteTransfert = this.quantiteTransfert
      this.phpPost({
        phpFile: "assets/php/modifierTransfert.php",
        sendObject: {
          transfertOriginal: this.transfert,
          transfertModifie: privateTransfert
        },
        callBack: (data)=>{
          // console. ("HERE");
          if (!data.reqSuccess) this.updateData()
          else this.$emit("update-liste")
        }
      })
    },
    supprimerTransfert(){
      this.$emit("supprimer-transfert")
    },
    updateData(){
      this.provenanceTransfert = this.listeLieux.findIndex((lieu)=>{
        return lieu.id_lieu == this.transfert.provenance.id_lieu
      })
      this.destinationTransfert = this.listeLieux.findIndex((lieu)=>{
        return lieu.id_lieu == this.transfert.destination.id_lieu
      })
      this.numeroTransfert = this.listeNumeros.findIndex((numero)=>{
        return numero.id_numero == this.transfert.id_numero
      })
      this.quantiteTransfert = this.transfert.quantite_transfert
    }
  },
  computed: {
    ...mapState(["listeLieux", "listeNumeros", "user"]),
    detailsLieuxTransfert() {
      return "De " + this.transfert.provenance.nom_lieu + " vers " + this.transfert.destination.nom_lieu
    }
  },
 
  mounted() {
    this.updateData()
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-details-transfert {
  background-color: $fond-page;
  // padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.supprimer-transfert {
  // width: 19px;
  color: $couleur-texte-fonce;
  margin-right: 20px;
  @include typo-medium;
}
.numero-transfert {
  width: 150px;
  color: $couleur-texte-fonce;
  margin-right: 10px;
  @include typo-medium;
}
.quantite-transfert {
  width: 80px;
  color: $couleur-texte-fonce;
  margin-right: 10px;
  @include typo-medium;
}
.details-lieux-transfert {
  width: 200px;
  color: $couleur-texte-fonce;
  @include typo-medium;
  &.labels{
    @include typo-medium;
    width: 40px;
  }
}
</style>